import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import reviewData from '../../data/reviews.json'
import Review from './Review'

const Reviews = () => {
  const data = useStaticQuery(query);
  return (
    <div className='
      pb-8
      sm:pb-16
      md:pb-20
    '>
      <h2 className='
        text-white
        pt-4
        pb-16
        md:text-3xl
        lg:text-4xl
      '>
        See What People Are Saying
      </h2>

      <div className='
        flex
        flex-row
        flex-wrap
        gap-16
        w-[80%]
        lg:max-w-5xl
        mx-auto
        justify-center
      '>
        {reviewData.map((review, index) => {
          return <Review
            reviewData={review}
            data={data}
            index={index}
          />
        })}
      </div>
    </div>
  )
}

const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "crabDesigns"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 100
          )
        }
        name
        relativePath
      }
    }
  }
`

export default Reviews

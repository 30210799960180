import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Hero = () => {
  const breakpoints = useBreakpoint();

  return (
    <header className=''>
      {breakpoints.sm ? 
          <StaticImage
            src="../../images/hero-mobile.png"
            alt="Crabs in a Bucket box art"
          />
      :
          <StaticImage
            src="../../images/hero-desktop.png"
            alt="Crabs in a Bucket box art"
          />
      }
    </header>
  )
};

export default Hero;

import * as React from "react"

import Layout from "../components/Layout";
import MiniCrabSelector from "../components/index/MiniCrabSelector";
import OceanSection from "../components/OceanSection";
import Reviews from "../components/index/Reviews";
import SandSection from "../components/SandSection";
import YoutubeEmbed from "../components/YoutubeEmbed";
import Hero from "../components/index/Hero";
import Metadata from "../components/Metadata";

const Homepage = () => {
  return (
    <Layout>
      <Metadata
        title={'Home'}
        slug={''}
      />
      <main>
        <Hero/>
        <section>
          <div className="
            bg-salmon
            text-2xl
            md:text-3xl
            text-white
            py-6
            text-center
            flex
            flex-col
            gap-y-4
            font-bold
          ">
            <p className="
              max-w-[18rem]
              sm:max-w-full
              mx-auto

            ">
              We are counting on YOU to lead the way!
            </p>
            <p>
              Here's how to play:
            </p>
          </div>
          <div className="
            bg-salmon-light
            py-8
          ">
            <YoutubeEmbed src="https://www.youtube.com/watch?v=TPZ_jlDCaY8"/>
          </div>
        </section>
        <SandSection>
          <div className="
            md:grid
            grid-cols-12
            pt-12
            md:pt-20
            md:w-3xl
            lg:max-w-5xl
            lg:pt-40
            xl-pt-40
            max-w-[80%]
            mx-auto
          ">
            <p className="
              md:pb-0
              pb-12
              md:max-w-[15rem]
              md:text-3xl
              lg:text-4xl
              max-w-[80%]
              w-60
              text-center
              text-2xl
              font-medium
              col-span-4
              m-auto
              text-brown-dark
            ">
              Use the power of the Joker Crabs wisely.
            </p>
            <div className="
              col-span-8
            ">
              <MiniCrabSelector/>
            </div>
          </div>
        </SandSection>

        <OceanSection>
          <Reviews/>
        </OceanSection>

      </main>
    </Layout>
  )
}

export default Homepage;

import React from 'react'
import WaveSvg from '../assets/wave-lg.svg';

const SandSection = ({ children }) => (
  <section className="
    radial
    w-screen
  ">
    
  {children}

  <WaveSvg className='
    fill-ocean-light
    -m-px
  '/>

  </section>
)

export default SandSection;

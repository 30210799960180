import React from 'react'
import OceanFloorSvg from '../assets/ocean-floor.svg';

const OceanSection = ({ children }) => (
  <section className="
    pt-8
    bg-gradient-to-b
    from-ocean-light
    to-ocean-dark
  ">

    {children}

    <OceanFloorSvg className='
      fill-ocean-floor
      md:-mt-20
      lg:-mt-32
      -mb-px
    '/>

  </section>
)

export default OceanSection

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Metadata = ({
  title,
  slug,
  description,
  keywords,
  children,
}) => {
  // Query for siteMetadata defined in gatsby-config.js
  const data = useStaticQuery(metadataQuery);

  // Create finalized data using given params and metadata query to use
  const finalMetadata = {
    allKeywords: 
      `${data.site.siteMetadata.keywords}${keywords ? `, ${keywords}` : ""}`,
    canonicalUrl: `${data.site.siteMetadata.siteUrl}/${slug}`,
    favicon: "/favicon.ico",
    ogImage: "/og-ciab-banner.jpeg",
    shortDescription: description || data.site.siteMetadata.description,
  };

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
    >
      {/* Data-based metadata */}
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Handle web-crawlers */}
      <meta name="robots" content="max-snippet:-1" />
      <meta name="robots" content="max-image-preview:standard" />

      {/* Site specific metadata */}
      <title>{title}</title>
      <meta name='description' content={finalMetadata.shortDescription} />
      <link rel='canonical' href={finalMetadata.canonicalUrl} />
      <link rel='shortcut icon' href={finalMetadata.favicon} />
      <meta name='keywords' content={finalMetadata.allKeywords} />

      {/* OpenGraph specific metadata */}
      <meta name='og:title' content={title} />
      <meta name='og:description' content={finalMetadata.shortDescription} />
      <meta name='og:image' content={finalMetadata.ogImage} />
      <meta name='og:type' content='website' />
      <meta name='og:url' content={finalMetadata.canonicalUrl} />
      <meta name='og:site_name' content={data.site.siteMetadata.title} />

      {/* Handle edge-case/custom metadata */}
      {children}
    </Helmet>
  );
};

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  children: PropTypes.any,
};

Metadata.defaultProps = {
  description: "",
  keywords: "",
  children: null,
}

const metadataQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        keywords
        title
        siteUrl
      }
    }
  }`;

export default Metadata;

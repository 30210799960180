import React from 'react';

const YoutubeEmbed = ({ src }) => {
  src = src.replace('watch?v=', 'embed/');
  return (
    <iframe
      className="
        aspect-video
        mx-auto
        w-10/12
        lg:max-w-2xl
        xl:max-w-3xl
        z-50
      "
      src={src}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen>
    </iframe> 
  )
};

YoutubeEmbed.propTypes = {
  src: String,
}

export default YoutubeEmbed;
